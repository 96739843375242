/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        //*Mobile Menu*//
        $( ".c-header__toggle" ).click(function(e) {
          e.preventDefault();

          $('.c-mobile__menu').animate({height: 'toggle'});
        });

        // for the mobile animation

        $(".c-header__toggle").click(function(){
          $(this).toggleClass("is-active");
        });


        // Display Reservation Search Form within Header
        $(".control-form").click(function(){
          $(".c-header").toggleClass("display-form");
        });

        //   hero Image
        var hero = $('.hero-back').data('desktop'),
        hero_mobile = $('.hero-back').data('mobile');
        if(viewportWidth < 767 ){
          $('.hero-back').css('background-image', 'url('+hero_mobile+')');
        }else{
          $('.hero-back').css('background-image', 'url('+hero+')');
        }

        //   About Image
        var about = $('.about-image').data('desktop'),
        about_mobile = $('.about-image').data('mobile');
        if(viewportWidth < 767 ){
          $('.about-image').css('background-image', 'url('+about_mobile+')');
        }else{
          $('.about-image').css('background-image', 'url('+about+')');
        }

        //   why Image
        var why = $('.section--why').data('desktop'),
        why_mobile = $('.section--why').data('mobile');
        if(viewportWidth < 767 ){
          $('.section--why').css('background-image', 'url('+why_mobile+')');
        }else{
          $('.section--why').css('background-image', 'url('+why+')');
        }
        //   career Image
        var career = $('.careers-image').data('desktop'),
        career_mobile = $('.careers-image').data('mobile');
        if(viewportWidth < 767 ){
          $('.careers-image').css('background-image', 'url('+career_mobile+')');
        }else{
          $('.careers-image').css('background-image', 'url('+career+')');
        }

        //   newsletter Image
        var newsletter = $('.section--newsletter').data('desktop'),
        newsletter_mobile = $('.section--newsletter').data('mobile');
        if(viewportWidth < 767 ){
          $('.section--newsletter').css('background-image', 'url('+newsletter_mobile+')');
        }else{
          $('.section--newsletter').css('background-image', 'url('+newsletter+')');
        }

        //   newsletter Image
        var intro = $('.section--intro').data('desktop'),
        intro_mobile = $('.section--intro').data('mobile');
        if(viewportWidth < 767 ){
          $('.section--intro').css('background-image', 'url('+intro_mobile+')');
        }else{
          $('.section--intro').css('background-image', 'url('+intro+')');
        }


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
